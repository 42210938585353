@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@font-face {
    font-family: 'TT Norms';
    src: url(/static/media/TTNorms-ExtraLight.3f6d6c2b.woff2) format('woff2'),
        url(/static/media/TTNorms-ExtraLight.1bd6ab17.woff) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms';
    src: url(/static/media/TTNorms-Medium.63f041b3.woff2) format('woff2'),
        url(/static/media/TTNorms-Medium.87a4f71c.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms';
    src: url(/static/media/TTNorms-Light.804736f8.woff2) format('woff2'),
        url(/static/media/TTNorms-Light.d306516b.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms';
    src: url(/static/media/TTNorms-Bold.9a14176c.woff2) format('woff2'),
        url(/static/media/TTNorms-Bold.473451fb.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms';
    src: url(/static/media/TTNorms-Thin.f822bd3a.woff2) format('woff2'),
        url(/static/media/TTNorms-Thin.fc32c192.woff) format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Norms';
    src: url(/static/media/TTNorms-Regular.8c418a8e.woff2) format('woff2'),
        url(/static/media/TTNorms-Regular.77e93370.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tele-Marines';
    src: url(/static/media/Tele-Marines.c6845ac3.woff2) format('woff2'),
        url(/static/media/Tele-Marines.349c2f23.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


* {
  box-sizing: border-box;
  -webkit-font-variant-ligatures: no-common-ligatures;
          font-feature-settings: "liga", "clig off";
          font-variant-ligatures: no-common-ligatures;
}

*::selection {
  background-color: #cf134b;
}

html {
  background-color: #0a1a32;
  color: #e7ecf4;
  font-family: "TT Norms";
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-size: 16px;
  text-decoration-thickness: 1px;
  scrollbar-width: none;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden auto;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

a {
  color: #e7ecf4;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

a:hover {
  color: #9da6ba;
}

#tsparticles {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#tsparticles:nth-of-type(1) {
  mix-blend-mode: difference;
}

#title-bg-img {
  position: absolute;
  z-index: -2;
  color: #e7ecf4;
  width: 95vw;
  height: 95vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

#title-bg-img svg {
  fill: #e7ecf4;
  opacity: 0.02;
  position: absolute;
  z-index: -1;
  max-width: 140vw;
  max-height: 140vh;
}

button,
.btn {
  font-family: "TT Norms";
  background: none;
  border: 0px #e7ecf4 solid;
  border-radius: 4px;
  cursor: pointer;
  color: #e7ecf4;
}

button:hover,
.btn:hover {
  text-decoration: underline;
}

button:focus,
.btn:focus {
  outline: none;
}

.nav-btn {
  padding: 13px;
  position: relative;
  color: #c0c7d6;
  transition: all ease-in-out 0.2s;
  text-decoration: none;
  padding-bottom: 15px;
}

.nav-btn::before {
  content: '';
  position: absolute;
  z-index: -1;
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #c0c7d6;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all ease-in-out 0.2s;
}

.nav-btn:hover {
  color: #0a1a32;
  text-decoration: none;
}

.nav-btn:hover::before {
  background-color: #e7ecf4;
  border-color: #e7ecf4;
  height: 120%;
}

.nav-btn:active::before {
  width: 110%;
  height: 140%;
}

.expanded {
  z-index: 2;
  color: #0a1a32;
}

.expanded:hover::before {
  border-color: #e7ecf4;
  background-color: #e7ecf4;
  width: 150vw;
}

.expanded::before {
  border-color: #e7ecf4;
  background-color: #e7ecf4;
  width: 150vw;
}

.bubble {
  position: absolute;
  z-index: -1;
  inset: 0px;
  width: 8px;
  height: 8px;
  margin: auto;
  border-radius: 50%;
  background: #e7ecf4;
  transform-origin: 0vw 0vh;
}

.button-cont {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socials svg {
  fill: #9da6ba;
  width: 6rem;
  transition: all 0.3s ease-in-out;
}

.socials svg:hover {
  transform: translate(0, -5px);
}

.socials #twitter-icon:hover {
  fill: #1DA1F2;
}

.socials #soundcloud-icon:hover {
  fill: #FF9533;
}

.socials #linkedin-icon:hover {
  fill: #006192;
}

.socials #youtube-icon:hover {
  fill: #e63838;
}

.socials #email-icon:hover {
  fill: #fff;
}

#title-card {
  text-align: center;
  height: 100%;
  width: 80%;
  -webkit-animation: title-fade 1s ease-in-out;
          animation: title-fade 1s ease-in-out;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10vh;
}

@media (max-width: 500px) {
  #title-card {
    padding-top: 0;
  }
}

#title-card #title {
  max-width: 700px;
  margin: 0 auto;
  flex: 1.3 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  color: #e7ecf4;
}

@media (max-width: 500px) {
  #title-card #title {
    flex: none;
  }
}

#title-card #title #font-logo {
  fill: #e7ecf4;
  width: 28rem;
  max-width: 100%;
}

#title-card #title #tagline {
  display: block;
  text-transform: lowercase;
  font-weight: 300;
  color: #e7ecf4;
  font-size: 1.625rem;
  letter-spacing: 0.25rem;
  margin-right: -0.25rem;
  max-width: 100%;
}

#title-card .divider {
  width: 100%;
  padding: 0;
  border: 0;
  margin: 1.5rem auto;
}

#title-card .divider::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 15%;
  min-width: 15rem;
  border-top: solid 2px #9da6ba;
  opacity: 1;
  height: 1px;
  margin-top: 20px;
}

#title-card nav {
  width: 100%;
  margin: 0 auto;
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: 500;
  flex: 1 1;
}

@media (max-width: 500px) {
  #title-card nav {
    flex: none;
  }
}

#title-card nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: auto;
}

@media (max-width: 500px) {
  #title-card nav ul {
    height: auto;
  }
}

#title-card nav ul li {
  height: auto;
  display: inline-block;
  margin: 1.1em 1em;
}

#title-card .socials {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 100%;
  margin-bottom: 2em;
}

#title-card .socials a {
  height: auto;
  max-width: 25%;
}

@media (max-width: 500px) {
  #title-card .socials svg {
    width: 20vw;
  }
}

#commissions {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

@media (max-width: 500px) {
  #commissions {
    align-items: flex-start;
  }
}

#commissions .container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
}

#commissions h2 {
  font-size: 2.25rem;
}

#commissions strong {
  font-size: 1.15rem;
}

#commissions p {
  margin-top: 1rem;
  margin-bottom: 0.3rem;
  margin-left: 1rem;
}

#commissions table {
  margin-left: 1.5rem;
}

#commissions td {
  padding: 0 1rem 0 0;
  vertical-align: top;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.95em;
}

#commissions td:first-child {
  width: 150px;
}

#commissions .li {
  list-style-type: disc;
  display: list-item;
  margin-left: 1.5rem;
}

#commissions > div {
  margin: 1em;
}

#commissions .prices {
  width: 400px;
  min-width: 380px;
  max-width: 700px;
  flex: 1 1;
}

@media (max-width: 500px) {
  #commissions .prices {
    min-width: auto;
  }
}

#commissions .examples {
  min-width: 500px;
  max-width: 756px;
  display: flex;
  flex: 2 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 5%;
}

@media (max-width: 500px) {
  #commissions .examples {
    min-width: 100%;
  }
  #commissions .examples iframe {
    width: 100%;
    height: 100%;
  }
}

#commissions .examples p {
  width: 100%;
  height: 0;
  position: absolute;
  padding-bottom: 50%;
  margin: 0;
}

#commissions .examples p strong {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
}

@media (max-width: 500px) {
  #commissions .examples p strong {
    top: -50px;
  }
}

#commissions .button-cont {
  padding-top: 5em;
  width: 100%;
}

@media (max-width: 500px) {
  #commissions .button-cont {
    padding-top: 0em;
  }
}

.example-container {
  position: relative;
  padding: 2em;
  height: 310px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {
  .example-container {
    padding: 0;
  }
}

.example-container .credits {
  width: 100%;
  height: 0;
  position: absolute;
  padding-bottom: 50%;
  z-index: -2;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  justify-content: center;
}

.example-container .credits span {
  position: absolute;
  z-index: -2;
  bottom: -30px;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
}

@media (max-width: 500px) {
  .example-container .credits span {
    bottom: -64px;
    max-width: 60%;
  }
}

.vid-parent-container {
  position: absolute;
  width: 100%;
  overflow: hidden;
  z-index: 10;
}

.vid-parent-container > div {
  margin: auto;
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}

.vid-parent-container iframe {
  max-width: 95vw;
  width: 80%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  z-index: 10;
}

.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  background-color: transparent;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 25px;
  z-index: 12;
  padding: 0 0 2px 2px;
}

.next::before,
.prev::before {
  background-color: transparent;
}

@media (max-width: 500px) {
  .next,
  .prev {
    top: auto;
    bottom: 0px;
  }
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
  transform: scale(-1);
}

.reel {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.reel img {
  border-radius: 4px;
  position: relative;
  z-index: 10;
}

.reel .container {
  max-width: 1200px;
  min-width: 63%;
  position: relative;
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  margin: 3rem;
  justify-content: center;
}

@media (max-width: 500px) {
  .reel .container {
    margin: 0 0 0 0;
    padding-bottom: 4em;
  }
}

.reel .container > div {
  min-width: 480px;
}

@media (max-width: 500px) {
  .reel .container > div {
    min-width: 100%;
  }
}

.reel h2 {
  font-size: 2.25rem;
  width: 100%;
  margin: 1em;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-right: 12px;
  padding-left: 12px;
  text-align: right;
}

@media (max-width: 500px) {
  .reel h2 {
    margin: 0;
    margin-bottom: 1em;
  }
}

.reel .release-card {
  display: inline-block;
  margin: 1em 1em;
  max-width: 120px;
  width: 25%;
  text-align: center;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  color: #e7ecf4;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 12;
}

.reel .release-card:hover {
  -webkit-text-decoration: underline 2px #9da6ba;
          text-decoration: underline 2px #9da6ba;
}

@media (max-width: 420px) {
  .reel .release-card {
    margin: 1em 0.5em;
  }
}

.reel .release-card .scaler {
  transition: all ease 0.3s;
  position: relative;
  z-index: 12;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.reel .release-card .scaler:hover {
  transform: scale(1.05);
}

.reel .release-card .outline {
  position: absolute;
  z-index: -1;
  border-radius: 12px;
  border-width: 2px;
  border-style: solid;
  border-color: #c0c7d6;
  width: 124%;
  height: 116%;
  top: -8%;
  left: -12%;
}

.reel .release-card img {
  max-width: 100%;
}

.reel .release-card-container {
  flex: 1 1;
  display: flex;
  flex-wrap: wrap;
  padding: 0 2em 2em 2em;
  justify-content: space-evenly;
  max-width: 800px;
  align-self: baseline;
}

@media (max-width: 500px) {
  .reel .release-card-container {
    padding: 0;
    padding-top: 2em;
  }
}

.reel .release-detail-pane {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  position: relative;
  max-width: 650px;
  border-radius: 12px;
}

.reel .release-detail-pane > div {
  margin: 0 auto;
}

.reel .release-detail-pane .spotify {
  width: calc(100% - 2em);
  margin: 1em;
  border-radius: 4px;
  position: relative;
  z-index: 10;
}

.reel .release-detail-pane .x {
  position: absolute;
  top: 1em;
  right: 1.5em;
  width: 24px;
  height: 24px;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  padding: 0 0 0 0;
  cursor: pointer;
  font-weight: bold;
  z-index: 0;
}

.reel .release-detail-pane .release-details {
  display: flex;
  align-items: flex-end;
  margin: 1em;
  margin-top: 0;
  overflow: hidden;
  min-height: 125px;
  flex-wrap: wrap;
}

@media (max-width: 420px) {
  .reel .release-detail-pane .release-details {
    min-height: 0;
    margin: 0;
  }
}

.reel .release-detail-pane .release-details .release-text {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  margin: 1em;
  margin-bottom: 0;
  width: auto;
  flex: 1 1;
}

@media (max-width: 420px) {
  .reel .release-detail-pane .release-details .release-text {
    margin-top: 0.5em;
    max-width: 80%;
  }
}

.reel .release-detail-pane .release-details .release-text h3 {
  font-weight: bold;
  font-size: 1.3rem;
  padding: 0;
  margin: 0;
  width: 100%;
}

.reel .release-detail-pane .release-details .release-text p {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9em;
}

.reel .release-detail-pane .release-details .release-text p a {
  color: #9da6ba;
}

.reel .release-detail-pane .release-details .release-text p a:hover {
  text-decoration: underline;
}

.reel .release-detail-pane .release-details img {
  width: 120px;
  display: inline;
}

@media (max-width: 420px) {
  .reel .release-detail-pane .release-details img {
    display: none;
  }
}

.reel .release-detail-pane .vid-parent-container {
  position: relative;
  width: 100%;
}

.reel .release-detail-pane .vid-parent-container iframe {
  z-index: 10;
  border-radius: 12px;
  width: calc(100% - 2em);
  height: calc(100% - 2em*9/16);
}

.reel .button-cont {
  width: 100%;
  position: absolute;
  bottom: -50px;
}

#demo-reel h2 {
  text-align: left;
}

#demo-reel .release-details {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

#demo-reel .release-text {
  margin-top: 0.5em;
  max-width: 80%;
  margin-left: 0;
}

#demo-reel iframe {
  z-index: 12;
}

#demo-reel #sc-iframe {
  border-radius: 12px;
  width: calc(100% - 2em);
  height: calc(100% - 2em*9/16);
}

#audio-controls {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
}

#audio-controls button {
  color: #c0c7d6;
}

#audio-controls .muted {
  text-decoration: line-through;
}

#audio-controls .slider-container {
  width: 60px;
  height: 16px;
  position: relative;
}

#audio-controls .slider-container:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  top: calc(8px - 2px);
  border-radius: 8px;
  background: #9da6ba;
}

#audio-controls .slider-fill {
  position: absolute;
  height: 4px;
  top: calc(8px - 2px);
  border-radius: 8px;
  background: #e7ecf4;
}

#audio-controls .knob {
  background: #0a1a32;
  border: 2px solid #e7ecf4;
  border-radius: 16px;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  z-index: 1;
  transition: background 0.2s ease-in-out;
  cursor: pointer;
}

#audio-controls .knob:hover {
  background: #e7ecf4;
}

#audio-controls-activate {
  position: fixed;
  width: 100%;
  bottom: 50px;
  text-align: center;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

#audio-controls-activate span {
  margin: 0 auto;
  text-decoration: underline;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.otherLinks {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 0.9em;
  padding: 12px;
}

.otherLinks a {
  color: #c0c7d6;
}

.otherLinks a:hover {
  color: #c0c7d6;
  text-decoration: underline;
}

[data-aos="slight-fade-left"] {
  opacity: 0.8;
  transform: translateX(5px);
  transition-property: opacity, transform;
}

[data-aos="slight-fade-left"].aos-animate {
  opacity: 1;
  transform: translateX(0);
}

[data-aos="slight-fade-right"] {
  opacity: 0.8;
  transform: translateX(-5px);
  transition-property: opacity, transform;
}

[data-aos="slight-fade-right"].aos-animate {
  opacity: 1;
  transform: translateX(0);
}
/*# sourceMappingURL=style.css.map */
